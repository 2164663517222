import {
  SET_MEDIA_QUERY_CLASS,
  SET_SELECTED_YEAR,
  NAVIGATE_TO_NEXT_YEAR,
  NAVIGATE_TO_PREVIOUS_YEAR,
  UPDATE_MEDIA_QUERY,
  SET_SUPPORTS_AVIF,
  SET_SUPPORTS_WEBP,
  AppActionTypes,
} from './actions'
import { getYears } from './selectors'
import {
  initialState,
  initialImageState,
  initialGalleryState,
  initialMediaQueryState,
} from './state'
import { MediaQueryClassType, Gallery, MediaQuery, RootState, Image } from './model'

function mediaQueryClass(state: MediaQueryClassType = '', action: AppActionTypes) {
  switch (action.type) {
    case SET_MEDIA_QUERY_CLASS:
      return action.payload.mediaQueryClass
  }

  return state
}

function selectedYear(state = '', getYears: () => string[], action: AppActionTypes) {
  const years = getYears()
  const currentIndex = years.indexOf(state)
  switch (action.type) {
    case SET_SELECTED_YEAR:
      return (years.indexOf(action.payload.year) !== -1 && action.payload.year) || state
    case NAVIGATE_TO_NEXT_YEAR:
      if (currentIndex - 1 >= 0) {
        return years[currentIndex - 1]
      }
      break
    case NAVIGATE_TO_PREVIOUS_YEAR:
      if (currentIndex + 1 < years.length) {
        return years[currentIndex + 1]
      }
      break
  }

  return state
}

function galleryImage(state: Image = initialImageState): Image {
  return {
    filename: state.filename,
    caption: state.caption,
  }
}

function galleryImages(state: Image[] = []): Image[] {
  return state.map((t) => galleryImage(t))
}

function galleryYear(state = ''): string {
  return state
}

function galleryText(state: string[] = []): string[] {
  return state
}

function gallery(state: Gallery = initialGalleryState): Gallery {
  return {
    year: galleryYear(state.year),
    text: galleryText(state.text),
    images: galleryImages(state.images),
  }
}

function galleries(state: Gallery[] = []): Gallery[] {
  return state.map((t) => gallery(t))
}

function mediaQuery(
  state: MediaQuery = initialMediaQueryState,
  action: AppActionTypes,
): MediaQuery {
  switch (action.type) {
    case UPDATE_MEDIA_QUERY:
      if (state.className === action.payload.className) {
        return Object.assign({}, state, {
          mediaQueryList: action.payload.mediaQueryList,
          mediaQuery: action.payload.mediaQueryList.media,
        })
      }
      break
  }

  return state
}

function mediaQueries(state: MediaQuery[] = [], action: AppActionTypes): MediaQuery[] {
  return state.map((t) => mediaQuery(t, action))
}

function supportsWebP(state: boolean | undefined = undefined, action: AppActionTypes) {
  switch (action.type) {
    case SET_SUPPORTS_WEBP:
      return action.payload.supportsWebP
  }

  return state
}

function supportsAvif(state: boolean | undefined = undefined, action: AppActionTypes) {
  switch (action.type) {
    case SET_SUPPORTS_AVIF:
      return action.payload.supportsAvif
  }

  return state
}

export function update(state = initialState, action: AppActionTypes): RootState {
  const getYearsSelector = () => getYears(state)
  return {
    supportsWebP: supportsWebP(state.supportsWebP, action),
    supportsAvif: supportsAvif(state.supportsAvif, action),
    mediaQueryClass: mediaQueryClass(state.mediaQueryClass, action),
    selectedYear: selectedYear(state.selectedYear, getYearsSelector, action),
    mediaQueries: mediaQueries(state.mediaQueries, action),
    galleries: galleries(state.galleries),
  }
}
