import { h, VNode } from 'snabbdom'

import { initialGalleryState, RootState, getYears, isLatestYearSelected } from './store'

import { contentView } from './content.component'
import { headerView } from './header.component'

export function view(state: RootState): VNode {
  const selectedGallerySelector = () =>
    state.galleries.find((t) => t.year === state.selectedYear) || initialGalleryState
  const getYearsSelector = () => getYears(state)
  const supportsWebPSelector = () => state.supportsWebP || false
  const supportsAvifSelector = () => /*state.supportsAvif ||*/ false
  const isLatestYearSelectedSelector = () => isLatestYearSelected(state)

  return h('div#content', [
    headerView(state, getYearsSelector, isLatestYearSelectedSelector),
    contentView(
      state,
      selectedGallerySelector,
      supportsWebPSelector,
      supportsAvifSelector,
      isLatestYearSelectedSelector,
    ),
  ])
}
