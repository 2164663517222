import { RootState } from './model'

export const getYears = (state: RootState): string[] =>
  state.galleries
    .map((t) => t.year)
    .sort((a, b) => {
      if (a > b) {
        return -1
      }
      if (a < b) {
        return 1
      }
      return 0
    })

export const getHistory = (state: RootState): { selectedYear: string } => ({
  selectedYear: state.selectedYear,
})

export const isLatestYearSelected = (state: RootState): boolean =>
  getYears(state).indexOf(state.selectedYear) === 0
