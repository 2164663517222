import { Gallery, MediaQuery, RootState, Image } from './model'
import data from './data.json'

export const initialMediaQueryState: MediaQuery = {
  mediaQueryList: null,
  className: '',
  folder: '',
  mediaQuery: '',
}

export const initialImageState: Image = {
  filename: '',
}

export const initialGalleryState: Gallery = {
  year: '',
  text: [],
  images: [],
}

export const initialState = data as RootState
