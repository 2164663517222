import { createStore } from 'redux'

import { AppActionTypes } from './actions'
import { update } from './reducers'
import { RootState } from './model'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: () => RootState
  }
}

export const store = createStore(update, window.__REDUX_DEVTOOLS_EXTENSION__?.())

export const dispatch = (action: AppActionTypes): AppActionTypes => store.dispatch(action)
export const getState = (): RootState => store.getState()
