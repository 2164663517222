const supportsAvif = new Promise<boolean>((resolve) => {
  const avif = new Image()
  avif.src
    = 'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A='
  avif.onload = () => resolve(true)
  avif.onerror = () => resolve(false)
})

const supportsWebP = new Promise<boolean>((resolve) => {
  const webp = new Image()
  webp.src = 'data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA=='
  webp.onload = () => resolve(true)
  webp.onerror = () => resolve(false)
})

export default function useImageType() {
  return {
    supportsAvif: () => supportsAvif,
    supportsWebP: () => supportsWebP,
  }
}
