import deepEqual from 'fast-deep-equal'

import { getState, dispatch, setSelectedYear, getHistory, store } from '../app/store'

function onPopStateChanged(event: { state: { selectedYear: string } }): void {
  dispatch(setSelectedYear(event.state.selectedYear))
}

function onStateChanged() {
  const state = getState()

  const pathname = `/${state.selectedYear}`
  const historyState = getHistory(state)
  const title = `${window.document.title} ${state.selectedYear}`
  if (!window.history.state) {
    window.history.replaceState(historyState, title, pathname)
  } else if (!deepEqual(window.history.state, historyState)) {
    window.history.pushState(historyState, title, pathname)
  }
}

export function useLocation(render: () => void): void {
  const state = getState()

  const pathname = window.location.pathname.replace('/', '')
  if (pathname.length > 0 && pathname !== state.selectedYear) {
    dispatch(setSelectedYear(pathname))
  } else {
    render()
  }

  window.onpopstate = onPopStateChanged
  store.subscribe(onStateChanged)
}
