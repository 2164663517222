import $ from 'jquery'

import {
  init,
  attributesModule,
  classModule,
  datasetModule,
  eventListenersModule,
  propsModule,
  VNode,
} from 'snabbdom'

import { getState } from './store'

import { view } from './app.component'

const patch = init([
  classModule,
  propsModule,
  attributesModule,
  datasetModule,
  eventListenersModule,
])

let vnode: VNode | HTMLElement

export function useApp(element: HTMLElement): void {
  vnode = element
}

export function render(): void {
  const state = getState()

  vnode = patch(vnode, view(state))
  // temporary workaround for polluted dom
  $('a[data-fancybox]').each((_, item) => {
    let prop: keyof typeof item
    for (prop in item) {
      if (prop.startsWith('jQuery')) {
        delete item[prop]
      }
    }
  })
}
