import { MediaQueryClassType } from './model'

export const SET_SELECTED_YEAR = 'SET_SELECTED_YEAR'
export const NAVIGATE_TO_NEXT_YEAR = 'NAVIGATE_TO_NEXT_YEAR'
export const NAVIGATE_TO_PREVIOUS_YEAR = 'NAVIGATE_TO_PREVIOUS_YEAR'
export const SET_MEDIA_QUERY_CLASS = 'SET_MEDIA_QUERY'
export const UPDATE_MEDIA_QUERY = 'UPDATE_MEDIA_QUERY'
export const SET_SUPPORTS_AVIF = 'SET_SUPPORTS_AVIF'
export const SET_SUPPORTS_WEBP = 'SET_SUPPORTS_WEBP'

interface SetSelectedYearAction {
  type: typeof SET_SELECTED_YEAR
  payload: { year: string }
}

export function setSelectedYear(year: string): AppActionTypes {
  return {
    type: SET_SELECTED_YEAR,
    payload: {
      year,
    },
  }
}

interface NavigateToNextYearAction {
  type: typeof NAVIGATE_TO_NEXT_YEAR
}

export function navigateToNextYear(): AppActionTypes {
  return {
    type: NAVIGATE_TO_NEXT_YEAR,
  }
}

interface NavigateToPreviousYearAction {
  type: typeof NAVIGATE_TO_PREVIOUS_YEAR
}

export function navigateToPreviousYear(): AppActionTypes {
  return {
    type: NAVIGATE_TO_PREVIOUS_YEAR,
  }
}

interface SetMediaQueryClassAction {
  type: typeof SET_MEDIA_QUERY_CLASS
  payload: { mediaQueryClass: MediaQueryClassType }
}

export function setMediaQueryClass(mediaQueryClass: MediaQueryClassType): AppActionTypes {
  return {
    type: SET_MEDIA_QUERY_CLASS,
    payload: {
      mediaQueryClass,
    },
  }
}

interface UpdateMediaQueryAction {
  type: typeof UPDATE_MEDIA_QUERY
  payload: { className: MediaQueryClassType; mediaQueryList: MediaQueryList }
}

export function updateMediaQuery(
  className: MediaQueryClassType,
  mediaQueryList: MediaQueryList,
): AppActionTypes {
  return {
    type: UPDATE_MEDIA_QUERY,
    payload: {
      className,
      mediaQueryList,
    },
  }
}

interface SetSupportsAvif {
  type: typeof SET_SUPPORTS_AVIF
  payload: { supportsAvif: boolean }
}

export function setSupportsAvif(supportsAvif: boolean): AppActionTypes {
  return {
    type: SET_SUPPORTS_AVIF,
    payload: {
      supportsAvif: supportsAvif,
    },
  }
}

interface SetSupportsWebP {
  type: typeof SET_SUPPORTS_WEBP
  payload: { supportsWebP: boolean }
}

export function setSupportsWebP(supportsWebP: boolean): AppActionTypes {
  return {
    type: SET_SUPPORTS_WEBP,
    payload: {
      supportsWebP: supportsWebP,
    },
  }
}

export type AppActionTypes =
  | SetSelectedYearAction
  | NavigateToNextYearAction
  | NavigateToPreviousYearAction
  | SetMediaQueryClassAction
  | UpdateMediaQueryAction
  | SetSupportsAvif
  | SetSupportsWebP
