import '../assets/fonts/fonts.css'
import './index.css'
import './gallery.css'

import { useServiceWorker } from './modules/service-worker-registration'
import { useLocation } from './modules/location'
import { useMediaQuery } from './modules/media-query'
import { useSwipe } from './modules/swipe'
import { useFancybox } from './modules/fancybox'
import { useApp, render } from './app/app'
import { dispatch, setSupportsAvif, setSupportsWebP, store } from './app/store'
import useImageType from './modules/imageType'

useServiceWorker()

const contentElement = document.getElementById('content')
if (!contentElement) {
  throw new Error('content element not found')
}
useApp(contentElement)
useLocation(render)
store.subscribe(render)

document.addEventListener('DOMContentLoaded', async () => {
  useMediaQuery()
  useSwipe(contentElement)
  useFancybox()

  const { supportsAvif, supportsWebP } = useImageType()
  const isAvifSupported = await supportsAvif()
  dispatch(setSupportsAvif(isAvifSupported))
  const isWebPSupported = await supportsWebP()
  dispatch(setSupportsWebP(isWebPSupported))
})
