import jquery from 'jquery'

window.jQuery = jquery

export default jquery

declare global {
  interface Window {
    jQuery: JQueryStatic
  }
}
