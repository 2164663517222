import hammer from 'hammerjs'

import { dispatch, navigateToNextYear, navigateToPreviousYear } from '../app/store'

export function useSwipe(container: HTMLElement): void {
  const swipe = new hammer(container, {
    domEvents: true,
  })

  swipe.on('swipe', function (event) {
    const direction = event.offsetDirection
    if (direction === 2) {
      dispatch(navigateToNextYear())
    } else if (direction === 4) {
      dispatch(navigateToPreviousYear())
    }
  })
}
