import { ArrayOrElement, h, VNodeChildElement, VNode } from 'snabbdom'

import { Gallery, Image, MediaQuery, RootState } from './store'

const commaSeparated = (acc: string, current: string): string => {
  if (acc) {
    return `${acc}, ${current}`
  }

  return current
}

interface ImageExtension {
  ext: string
  type: string
}

const webpType: ImageExtension = {
  ext: 'webp',
  type: 'image/webp',
}

const avifType: ImageExtension = {
  ext: 'avif',
  type: 'image/avif',
}

const jpgType: ImageExtension = {
  ext: 'jpg',
  type: 'image/jpeg',
}

const LINK_CUSTOM_ELEMENT = 'link'

interface CustomElementAttribute {
  name: string
  value: string
}

interface LinkCustomElement {
  type: typeof LINK_CUSTOM_ELEMENT
  index: number
  input: string
  url: string
}

type CustomElementType = LinkCustomElement

const itemRegex = new RegExp(/(?:\[)(link)(\s*\w+=".*?")(?:\])/g)
const attributesRegex = new RegExp(/(?:\s*)(\w+)=\"(.*?)\"/g)

function createCustomElements(content: string): CustomElementType[] {
  const customElements: CustomElementType[] = []
  let itemMatches: RegExpExecArray | null
  while ((itemMatches = itemRegex.exec(content)) !== null) {
    const attributes: CustomElementAttribute[] = []
    let attributeMatches: RegExpExecArray | null
    while ((attributeMatches = attributesRegex.exec(itemMatches[0])) !== null) {
      attributes.push({
        name: attributeMatches[1],
        value: attributeMatches[2],
      })
    }

    if (itemMatches[1] === 'link') {
      const urlAttribute = attributes.find((t) => t.name === 'url')
      if (!urlAttribute) {
        throw new Error('attribute "url" missing')
      }

      const customElement: LinkCustomElement = {
        type: LINK_CUSTOM_ELEMENT,
        index: 0,
        input: itemMatches[0],
        url: urlAttribute.value,
      }
      customElements.push(customElement)
    }
  }

  return customElements
}

function contentGalleryItemView(
  year: string,
  mediaQueries: MediaQuery[],
  image: Image,
  supportsWebP: boolean,
  supportsAvif: boolean,
): VNode {
  const { ext }: ImageExtension = supportsAvif ? avifType : supportsWebP ? webpType : jpgType
  const srcset = mediaQueries
    .map(
      (t) =>
        `/assets/images/galleries/${year}/${t.folder}/${image.filename}.${ext} ${
          t.maxWidth ? `${t.maxWidth}w` : ''
        }`,
    )
    .reduce(commaSeparated)
  return h(
    'a',
    {
      props: {
        href: 'javascript:;',
      },
      dataset: {
        srcset,
        caption: image.caption || '',
        fancybox: 'gallery',
      },
    },
    [
      h('picture', [
        /*h('source', {
          props: {
            srcset: `/assets/images/galleries/${year}/300/${image.filename}.avif`,
            type: 'image/avif',
          },
        }),*/
        h('source', {
          props: {
            srcset: `/assets/images/galleries/${year}/300/${image.filename}.webp`,
            type: 'image/webp',
          },
        }),
        h('img', {
          attrs: {
            alt: image.caption || '',
          },
          props: {
            src: `/assets/images/galleries/${year}/300/${image.filename}.jpg`,
          },
        }),
      ]),
    ],
  )
}

function contentGalleryView(
  state: RootState,
  getSelectedGallery: () => Gallery,
  supportsWebP: () => boolean,
  supportsAvif: () => boolean,
): VNode {
  const gallery = getSelectedGallery()
  const images = gallery.images.map((t) =>
    contentGalleryItemView(gallery.year, state.mediaQueries, t, supportsWebP(), supportsAvif()),
  )
  return h('div.images.gallery', images)
}

function contentTextView(getSelectedGallery: () => Gallery): VNode {
  const gallery = getSelectedGallery()
  let index = 0
  const customElementsMap = gallery.text
    .map(createCustomElements)
    .reduce((acc, customElements) => [...acc, ...customElements], [])
    .reduce(
      (acc, customElement) =>
        (customElement?.input && acc.set(customElement.input, customElement)) || acc,
      new Map<string, CustomElementType>(),
    )

  const links = Array.from(customElementsMap.values())
    .filter((t) => t.type === 'link')
    .map((t) =>
      Object.assign({}, t, {
        index: ++index,
      }),
    )

  let linksElements: ArrayOrElement<VNodeChildElement> = []
  if (links && links.length > 0) {
    linksElements = [
      'Links',
      h(
        'ul',
        links.map((t) =>
          h('li', [
            `[${t.index}]:`,
            h(
              'a',
              {
                attrs: {
                  href: t.url,
                  target: '_blank',
                },
              },
              t.url,
            ),
          ]),
        ),
      ),
    ]
  }

  return h('div.text', [
    ...gallery.text.map((t) => {
      for (const link of links) {
        t = t.replace(link.input, `[${link.index}]`)
      }
      return h('p', t)
    }),
    ...linksElements,
  ])
}

function contentHeaderView(state: RootState, isLatestYear: () => boolean): VNode {
  return h('h2', [
    h('span.a', 'Welcome!'),
    h('span.b', 'CLEK'),
    h('span.c', '(Claudio, Luca, Elia, Katharina)'),
    h('div.d', [
      'year ',
      h('span.year', { class: { latest: isLatestYear() } }, state.selectedYear),
      ' in a few lines',
    ]),
  ])
}

export function contentView(
  state: RootState,
  getSelectedGallery: () => Gallery,
  supportsWebP: () => boolean,
  supportsAvif: () => boolean,
  isLatestYearSelected: () => boolean,
): VNode {
  return h('main#shell', [
    h('article#gallery', [
      contentHeaderView(state, isLatestYearSelected),
      contentTextView(getSelectedGallery),
      contentGalleryView(state, getSelectedGallery, supportsWebP, supportsAvif),
    ]),
  ])
}
