import $ from '../jquery-global'
import '@fancyapps/fancybox'
import '@fancyapps/fancybox/dist/jquery.fancybox.css'

export function useFancybox(): void {
  $().fancybox({
    animationEffect: false,
    transitionEffect: false,
    idleTime: undefined,
    buttons: ['slideShow', 'fullScreen', 'close'],
  })
}
