import { getState, dispatch, setMediaQueryClass, updateMediaQuery } from '../app/store'

function onMediaQueryChange(event?: MediaQueryListEvent): void {
  const state = getState()
  let mediaQuery: MediaQueryList | undefined
  if (!event) {
    mediaQuery
      = state.mediaQueries.map((t) => t.mediaQueryList).find((t) => t?.matches === true) || undefined
  }

  if (mediaQuery && mediaQuery.matches === true) {
    const x = mediaQuery
    const className = state.mediaQueries.find((t) => t.mediaQuery === x.media)?.className || ''
    dispatch(setMediaQueryClass(className))
  }
}

export function useMediaQuery(): void {
  if (!window.matchMedia) {
    return
  }

  const state = getState()
  state.mediaQueries.forEach((element) => {
    const mediaQueryList = window.matchMedia(element.mediaQuery)
    mediaQueryList.addEventListener('change', onMediaQueryChange)

    dispatch(updateMediaQuery(element.className, mediaQueryList))
  })

  onMediaQueryChange()
}
