import { h, VNode } from 'snabbdom'

import { dispatch, setSelectedYear, RootState } from './store'

function yearSelectionItemView(state: string, selected: boolean): VNode {
  return h(
    'option',
    {
      props: {
        value: state,
        selected,
      },
      attrs: {
        selected,
      },
    },
    state,
  )
}

function yearSelectionView(state: RootState, getYears: () => string[]): VNode {
  return h('div.item.gallery-selection', [
    h(
      'label',
      {
        attrs: {
          for: 'availableGalleries',
        },
      },
      'year',
    ),
    h(
      'select#availableGalleries',
      {
        attrs: {
          tabindex: 0,
        },
        on: {
          change: (event) => {
            const element = event.target as HTMLSelectElement
            if (element) {
              element.blur()
              dispatch(setSelectedYear(element.value))
            }
          },
        },
      },
      getYears().map((t) => yearSelectionItemView(t, state.selectedYear === t)),
    ),
  ])
}

function headerLogo(): VNode {
  return h('div.item', [
    h('img.logo', {
      props: {
        src: '/assets/images/logo.png',
        alt: 'CLEK logo',
      },
    }),
    h(
      'a.logo',
      {
        props: {
          href: '/',
        },
      },
      [h('span.c', 'c'), h('span.l', 'l'), h('span.e', 'e'), h('span.k', 'k')],
    ),
  ])
}

export function headerView(
  state: RootState,
  getYears: () => string[],
  isLatestYearSelected: () => boolean,
): VNode {
  return h('nav.menu', { class: { latest: isLatestYearSelected() } }, [
    h('div.container', [headerLogo(), yearSelectionView(state, getYears)]),
  ])
}
